import ApiService from '@/services'

const actions = {
  fetchPoints({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.map.getPoints()
        .then((response) => {
          commit('setPoints', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCacheLifeTime({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.map.getCacheLifeTime()
        .then((response) => {
          commit('setCacheLifeTime', response?.data?.tileCacheLifetime)
          resolve(response?.data?.tileCacheLifetime)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  setCountEfisFilter(state) {
    state.countEfisFilter += 1
  },
  setCountWinterFilter(state) {
    state.countWinterFilter += 1
  },
  setCountSummerFilter(state) {
    state.countSummerFilter += 1
  },
  setEditingGroup(state, data) {
    state.editingGroup = data
  },
  setIsFindPolygon(state, data) {
    state.isFindPolygon = data
  },
  setPoints(state, data) {
    state.points = data
  },
  setCenterArea(state, data) {
    state.centerArea = data
  },
  setOldPolygon(state, data) {
    state.oldPolygon = data
  },
  setView(state, data) {
    state.view = data
  },
  setDeletingPin(state, data) {
    state.deletingPin = data
  },
  setEditingPin(state, data) {
    state.editingPin = data
  },
  setIsShowMarkerInfoPopup(state, data) {
    state.isShowMarkerInfoPopup = data
  },
  setCreatingPinPoint(state, data) {
    state.creatingPinPoint = data
  },
  setCacheLifeTime(state, data) {
    state.cacheLifeTime = data
  },
  setMode(state, data) {
    state.mode = data
  },
  setGroupMode(state, data) {
    state.groupMode = data
  },
  setSearchingPoint(state, data) {
    state.searchingPoint = data
  },
  setCountDeletingOverlaps(state) {
    state.countDeletingOverlaps += 1
  },
  setCountEditingPolygon(state) {
    state.countEditingPolygon += 1
  },
  setCountConfirmCheckPolygon(state) {
    state.countConfirmCheckPolygon += 1
  },
  setCountConfirmCheckPolygonWithoutCurrent(state) {
    state.countConfirmCheckPolygonWithoutCurrent += 1
  },
  setPolygonFromXml(state, data) {
    state.polygonFromXml = data
  },
  setOverlaps(state, data) {
    state.overlaps = data
  },
  setActions(state, data) {
    state.actions = data
  },
  setCreatingSection(state, data) {
    state.creatingSection = data
  },
  setIsCreateSection(state, data) {
    state.isCreateSection = data
  },
  setErrorKn(state, data) {
    state.errorKn = data
  },
  setVisibleEntity(state, data) {
    state.visibleEntity = data
  },
  setCheckedAdditionalLayers(state, data) {
    state.checkedAdditionalLayers = data
  },
  setMapState(state, data) {
    state.mapState = data
  },
  setModeState(state, data) {
    state.modeState = data
  },
  setEditingPolygon(state, data) {
    state.editingPolygon = data
  },
  setInheriteAttributeSections(state, data) {
    state.inheriteAttributesSections = data
  }
}

const state = () => ({
  // layersGroup: [
  //   { name: 'КУГИ', value: 'kugi', color: '#4970E5' },
  //   { name: 'КУМИ', value: 'kumi', color: '#4970E5' }, // '#b625f5'
  //   { name: 'Росреестр', value: 'rosreestr', color: '#99E52E' },
  //   { name: 'Паевые земли', value: 'pais', color: '#4970E5' }, // '#4970E5'
  //   { name: 'Федеральные земли', value: 'federal', color: '#4970E5' }, // '#fa6400'
  //   { name: 'Участки на оформлении', value: 'rosreestr2', color: '#4970E5' } // '#00ffbf'
  // ],
  layersGroup: [
    { value: 'accounted', label: 'Учтенные участки', name: 'Учтенные участки', color: '#99E52E' },
    { value: 'unaccounted', label: 'Неучтенные участки', name: 'Неучтенные участки', color: '#4970E5' }
  ],
  mapState: 'static', // ['edit', 'static']
  modeState: 'default', // ['default', 'create', 'edit']
  groupMode: 'layer', // ['ownership', 'layer', 'exploitation', 'land', 'complexity', 'use']
  mode: 'default', // ['default', 'edit-group', 'create-scheme', 'create-field', 'create-field-paint', 'create-field-section', 'auto-create-field', 'edit-field', 'group-edit-field', 'fit-polygon', 'split-field', 'join-fields']
  searchingPoint: [],
  cacheLifeTime: 36000,
  creatingPinPoint: [],
  deletingPin: null,
  editingPin: null,
  isShowMarkerInfoPopup: false, // [true, false]
  editingGroup: {},
  oldPolygon: null,
  view: 'map', // ['map', 'table']
  centerArea: null,
  points: [],
  isFindPolygon: false,
  countDeletingOverlaps: 0,
  countEditingPolygon: 0,
  countConfirmCheckPolygon: 0,
  countConfirmCheckPolygonWithoutCurrent: 0,
  polygonFromXml: null,
  overlaps: [],
  actions: [],
  creatingSection: null,
  errorKn: '',
  visibleEntity: null, // [null, 'fields', 'sections', 'schemes']
  isCreateSection: false,
  countEfisFilter: 0,
  countWinterFilter: 0,
  countSummerFilter: 0,
  checkedAdditionalLayers: [],
  editingPolygon: null,
  inheriteAttributesSections: [],
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
