import ApiService from '@/services'

const state = {
  periods: [],
  typeCulture: [],
  landOwnerships: [],
  actualExploitations: [],
  eventsType: [],
  regions: [],
  kugiUseCategories: [],
  complexityExploitation: [],
  landTypes: [],
  layers: [],
  checkStatuses: [],
  checkInnerStatuses: [],
  orgs: [],
  rules: [],
  fieldRules: [],
  searchTypes: [],
  deletedStatus: [
    { name: 'Все', label: 'Все', value: 'show' },
    { name: 'Неудаленные', label: 'Неудаленные', value: 'hide' },
    { name: 'Удаленные', label: 'Удаленные', value: 'only' }
  ],
  userFact: [
    { name: 'Все', label: 'Все', value: 'all' },
    { name: 'Да', label: 'Да', value: 'yes' },
    { name: 'Нет', label: 'Нет', value: 'no' }
  ],
  groups: [
    { name: 'Без группировки', label: 'Без группировки', value: null },
    { name: 'По муниципалитету', label: 'По муниципалитету', value: 'region' },
    { name: 'По муниципалитету пользователя', label: 'По муниципалитету пользователя', value: 'userRegion' },
    { name: 'По пользователю', label: 'По пользователю', value: 'user' },
    { name: 'По дате', label: 'По дате', value: 'date' },
    { name: 'По действию', label: 'По действию', value: 'type' }
  ],
  overlapStatus: [],
  isLoadingOrgs: false,
  hasOverlapsPkkStatus: [],
  fieldAvailableStatus: [],
  fieldLandTypes: [],
  operationYears: [],
  especiallyTypes: [
    { name: 'Да', label: 'Да', value: true },
    { name: 'Нет', label: 'Нет', value: false }
  ],
  efisDataCultureRotation: [],
  efisDataUnusedLandGroups: [],
  rrUpdateStatuses: [],
  orgTypes: [],
  landSurvey: [],
  landCoverAnalysis: [],
  cultureGroups: [],
  orgDadataStatuses: [],
  orgStatuses: [],
  landPermittedUseTypes: [],
  efisGp: [],
  efisMicrobio: [],
  efisOkpd2: [],
  efisRecUse: [],
  efisVegType: [],
  efisYearUse: [],
  siderateTypes: [
    { name: 'Нет', label: 'Нет', value: 0 },
    { name: 'Да', label: 'Да', value: 1 }
  ]
}

const mutations = {
  setOverlapStatus(state, data) {
    state.overlapStatus = data.map((item) => ({ ...item, label: item.name }))
  },
  setHasOverlapsPkkStatus(state, data) {
    state.hasOverlapsPkkStatus = data.map((item) => ({ ...item, label: item.name }))
  },
  setLandOwnerships(state, data) {
    state.landOwnerships = data.map((item) => ({ ...item, label: item.name }))
  },
  setLayers(state, data) {
    state.layers = data.map((item) => ({ ...item, label: item.name }))
  },
  setRegions(state, data) {
    state.regions = data.map((item) => ({ ...item, label: item.name }))
  },
  setActualExploitations(state, data) {
    state.actualExploitations = data.map((item) => ({ ...item, label: item.name }))
  },
  setLandTypes(state, data) {
    state.landTypes = data.map((item) => ({ ...item, label: item.name }))
  },
  setFieldLandTypes(state, data) {
    state.fieldLandTypes = data.map((item) => ({ ...item, label: item.name }))
  },
  setTypeCulture(state, data) {
    state.typeCulture = data.map((item) => ({ ...item, label: item.name }))
  },
  setEventsType(state, data) {
    state.eventsType = data.map((item) => ({ ...item, label: item.name }))
  },
  setKugiUseCategories(state, data) {
    state.kugiUseCategories = data.map((item) => ({ ...item, label: item.name }))
  },
  setComplexityExploitation(state, data) {
    state.complexityExploitation = data.map((item) => ({ ...item, label: item.name }))
  },
  setCheckStatuses(state, data) {
    state.checkStatuses = data.map((item) => ({ ...item, label: item.name }))
  },
  setCheckInnerStatuses(state, data) {
    state.checkInnerStatuses = data.map((item) => ({ ...item, label: item.name }))
  },
  setOrgs(state, data) {
    state.orgs = data.map((item) => ({ ...item, value: item.id, label: item.name }))
  },
  setIsLoadingOrgs(state, data) {
    state.isLoadingOrgs = data
  },
  setRules(state, data) {
    state.rules = data
  },
  setFieldRules(state, data) {
    state.fieldRules = data
  },
  setSearchTypes(state, data) {
    state.searchTypes = data.map((item) => ({ ...item, label: item.name }))
  },
  setPeriods(state, data) {
    state.periods = data.map((item) => ({ ...item, value: item.id, label: item.name }))
  },
  setFieldAvailableStatus(state, data) {
    state.fieldAvailableStatus = data.map((item) => ({ ...item, value: Number(item.value), label: item.name }))
  },
  setOperationYears(state, data) {
    state.operationYears = data.map((item) => ({ ...item, label: item.name }))
  },
  setEfisDataCultureRotation(state, data) {
    state.efisDataCultureRotation = data.map((item) => ({ ...item, label: item.name }))
  },
  setEfisDataUnusedLandGroups(state, data) {
    state.efisDataUnusedLandGroups = data.map((item) => ({ ...item, label: item.name }))
  },
  setRrUpdateStatuses(state, data) {
    state.rrUpdateStatuses = data.map((item) => ({ ...item, label: item.name }))
  },
  setOrgTypes(state, data) {
    state.orgTypes = data.map((item) => ({ ...item, label: item.name }))
  },
  setLandSurvey(state, data) {
    state.landSurvey = data.map((item) => ({ ...item, label: item.name }))
  },
  setLandCoverAnalysis(state, data) {
    state.landCoverAnalysis = data.map((item) => ({ ...item, label: item.name }))
  },
  setCultureGroups(state, data) {
    state.cultureGroups = data
  },
  setOrgDadataStatuses(state, data) {
    state.orgDadataStatuses = data
  },
  setOrgStatuses(state, data) {
    state.orgStatuses = data
  },
  setLandPermittedUseTypes(state, data) {
    state.landPermittedUseTypes = data.map((item) => ({ ...item, label: item.name }))
  },
  setEfisGp(state, data) {
    state.efisGp = data.map((item) => ({ ...item, label: item.name }))
  },
  setEfisMicrobio(state, data) {
    state.efisMicrobio = data.map((item) => ({ ...item, label: item.name }))
  },
  setEfisOkpd2(state, data) {
    state.efisOkpd2 = data.map((item) => ({ ...item, label: item.name }))
  },
  setEfisRecUse(state, data) {
    state.efisRecUse = data.map((item) => ({ ...item, label: item.name }))
  },
  setEfisVegType(state, data) {
    state.efisVegType = data.map((item) => ({ ...item, label: item.name }))
  },
  setEfisYearUse(state, data) {
    state.efisYearUse = data.map((item) => ({ ...item, label: item.name }))
  },
}
const actions = {
  fetchDictionaries({ commit }) {
    if (
      state.searchTypes.length < 1 ||
      state.eventsType.length < 1 ||
      state.actualExploitations.length < 1 ||
      state.checkStatuses.length < 1 ||
      state.checkInnerStatuses.length < 1 ||
      state.complexityExploitation.length < 1 ||
      state.cultureGroups.length < 1 ||
      state.efisDataCultureRotation.length < 1 ||
      state.efisDataUnusedLandGroups.length < 1 ||
      state.fieldAvailableStatus.length < 1 ||
      state.fieldLandTypes.length < 1 ||
      state.fieldRules.length < 1 ||
      state.hasOverlapsPkkStatus.length < 1 ||
      state.operationYears < 1 ||
      state.kugiUseCategories.length < 1 ||
      state.landCoverAnalysis.length < 1 ||
      state.landOwnerships.length < 1 ||
      state.landPermittedUseTypes.length < 1 ||
      state.landSurvey.length < 1 ||
      state.landTypes.length < 1 ||
      state.layers.length < 1 ||
      state.overlapStatus.length < 1 ||
      state.orgDadataStatuses.length < 1 ||
      state.orgStatuses.length < 1 ||
      state.orgTypes.length < 1 ||
      state.periods.length < 1 ||
      state.regions.length < 1 ||
      state.rrUpdateStatuses.length < 1 ||
      state.rules.length < 1
    ) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getDictionaries()
          .then((response) => {
            commit('setSearchTypes', response.data.actionSearchTypes ?? [])
            commit('setEventsType', response.data.actionTypes ?? [])
            commit('setActualExploitations', response.data.actualExploitations ?? [])
            commit('setCheckStatuses', response.data.checkStatuses ?? [])
            commit('setCheckInnerStatuses', response.data.checkStatusesInner ?? [])
            commit('setComplexityExploitation', response.data.complexityExploitation ?? [])
            commit('setCultureGroups', response.data.cultureGroups ?? [])
            commit('setEfisDataCultureRotation', response.data.efisDataCultureRotation ?? [])
            commit('setEfisDataUnusedLandGroups', response.data.efisDataUnusedLandGroups ?? [])
            commit('setEfisGp', response.data.efisGp ?? [])
            commit('setEfisMicrobio', response.data.efisMicrobio ?? [])
            commit('setEfisOkpd2', response.data.efisOkpd2 ?? [])
            commit('setEfisRecUse', response.data.efisReUse ?? [])
            commit('setEfisVegType', response.data.efisVegType ?? [])
            commit('setEfisYearUse', response.data.efisYearUse ?? [])
            commit('setFieldLandTypes', response.data.fieldLandTypes ?? [])
            commit('setOperationYears', response.data.fieldOperationYears ?? [])
            commit('setFieldRules', response.data.fieldRules ?? [])
            commit('setFieldAvailableStatus', response.data.fieldsAvailableStatuses ?? [])
            commit('setOverlapStatus', response.data.hasOverlaps ?? [])
            commit('setHasOverlapsPkkStatus', response.data.hasOverlapsPkk ?? [])
            commit('setKugiUseCategories', response.data.kugiUseCategory ?? [])
            commit('setLandCoverAnalysis', response.data.landCoverAnalysis ?? [])
            commit('setLandOwnerships', response.data.landOwnership ?? [])
            commit('setLandPermittedUseTypes', response.data.landPermittedUseType ?? [])
            commit('setLandSurvey', response.data.landSurvey ?? [])
            commit('setLandTypes', response.data.landTypes ?? [])
            commit('setLayers', response.data.layers ?? [])
            commit('setOrgDadataStatuses', response.data.orgDadataStatuses ?? [])
            commit('setOrgStatuses', response.data.orgStatuses ?? [])
            commit('setOrgTypes', response.data.orgTypes ?? [])
            commit('setPeriods', response.data.periods ?? [])
            commit('setRegions', response.data.regionNames ?? [])
            commit('setRrUpdateStatuses', response.data.rrUpdateStatuses ?? [])
            commit('setRules', response.data.rules ?? [])
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchPeriods({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.dictionaries.getPeriods()
        .then((response) => {
          commit('setPeriods', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSearchTypes({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.dictionaries.getSearchTypes()
        .then((response) => {
          commit('setSearchTypes', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchRules({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.dictionaries.getRules()
        .then((response) => {
          commit('setRules', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchFieldRules({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.dictionaries.getFieldRules()
        .then((response) => {
          commit('setFieldRules', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchOrgs({ commit }) {
    commit('setIsLoadingOrgs', true)
    return new Promise((resolve, reject) => {
      ApiService.dictionaries.getOrgs()
        .then((response) => {
          commit('setOrgs', response.data)
          commit('setIsLoadingOrgs', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingOrgs', false)
          reject(error)
        })
    })
  },
  fetchLayers({ commit }) {
    if (state.layers.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getLayers()
          .then((response)=> {
            commit('setLayers', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchComplexityExploitation({ commit }) {
    if (state.complexityExploitation.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getComplexityExploitation()
          .then((response)=> {
            commit('setComplexityExploitation', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchLandTypes({ commit }) {
    if (state.landTypes.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getLandTypes()
          .then((response)=> {
            commit('setLandTypes', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchRegions({ commit }) {
    if (state.regions.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getRegions()
          .then((response)=> {
            commit('setRegions', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchEventsType({ commit }) {
    if (state.eventsType.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getEventsType()
          .then((response)=> {
            commit('setEventsType', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchLandOwnerships({ commit }) {
    if (state.landOwnerships.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getLandOwnerships()
          .then((response)=> {
            commit('setLandOwnerships', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchActualExploitations({ commit }) {
    if (state.actualExploitations.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getActualExploitations()
          .then((response)=> {
            commit('setActualExploitations', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchTypeCulture({ commit }) {
    if (state.typeCulture.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getTypeCulture()
          .then((response)=> {
            commit('setTypeCulture', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchKugiUseCategories({ commit }) {
    if (state.kugiUseCategories.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getKugiUseCategories()
          .then((response)=> {
            commit('setKugiUseCategories', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchCheckStatuses({ commit }) {
    if (state.checkStatuses.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getCheckStatuses()
          .then((response)=> {
            commit('setCheckStatuses', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchCheckInnerStatuses({ commit }) {
    if (state.checkInnerStatuses.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getCheckInnerStatuses()
          .then((response)=> {
            commit('setCheckInnerStatuses', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchOperationYears({ commit }) {
    if (state.operationYears.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getOperationYears()
          .then((response)=> {
            commit('setOperationYears', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchEfisDataCultureRotation({ commit }) {
    if (state.efisDataCultureRotation.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getEfisDataCultureRotation()
          .then((response)=> {
            commit('setEfisDataCultureRotation', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchEfisDataUnusedLandGroups({ commit }) {
    if (state.efisDataUnusedLandGroups.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getEfisDataUnusedLandGroups()
          .then((response)=> {
            commit('setEfisDataUnusedLandGroups', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  fetchOrgTypes({ commit }) {
    if (state.orgTypes.length < 1) {
      return new Promise((resolve, reject) => {
        ApiService.dictionaries.getOrgTypes()
          .then((response)=> {
            commit('setOrgTypes', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  // getters,
}
