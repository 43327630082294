<template>
<q-form ref="fields">
  <div v-access="'fields_filter_period_ids'" class="filter__section">
    <Select
      v-model="fieldsFilter.periodIds"
      label="Сезон поля"
      :options="periods"
      :clearable="true"
      :multiple="true"
    />
  </div>
  <template v-if="fieldsFilter.periodIds.length > 0">
    <div v-access="'fields_filter_area'" class="label label-select" aria-label="filter-field-area">Площадь поля, Га.</div>
    <div v-access="'fields_filter_area'" class="filter__section filter__section--validate filter__section_flex">
      <div class="input">
        <span class="label-select">от</span>
        <q-input
          v-model.number="fieldsFilter.fieldAreaMin"
          type="number"
          outlined
          placeholder="от"
          :rules="[val => val >= 0 || 'Меньше 0']"
          dense
        />
      </div>
      <div class="input">
        <span class="label-select">до</span>
        <q-input
          v-model.number="fieldsFilter.fieldAreaMax"
          type="number"
          outlined
          placeholder="до"
          :rules="[val => val >= 0 || 'Меньше 0', val => !val || val >= fieldsFilter.fieldAreaMin || 'Меньше от']"
          dense
        />
      </div>
    </div>
    <div v-access="'fields_filter_field_id'" class="filter__section">
      <div class="input input-search">
        <span class="label-select">Поиск по номеру поля</span>
        <q-input
          v-model="fieldsFilter.fieldId"
          outlined
          placeholder="Поиск по номеру поля"
          dense
        />
      </div>
    </div>
    <div v-access="'fields_filter_efis_number'" class="filter__section">
      <div class="input input-search">
        <span class="label-select">Номер поля в ЕФИС ЗСН</span>
        <q-input
          v-model="fieldsFilter.efisNumber"
          outlined
          placeholder="Номер поля в ЕФИС ЗСН"
          dense
        />
      </div>
    </div>
    <div v-if="municipalities.length > 0 && !user.region" class="filter__section">
      <Select
        label="Муниципалитет"
        v-model="fieldsFilter.regionIds"
        :options="sortOptions(municipalities, fieldsFilter.regionIds)"
        :isSort="true"
        :clearable="true"
        :allable="true"
        :multiple="true"
        :use-input="true"
      />
    </div>
    <div v-access="'fields_filter_actual_exploitation_ids'" class="filter__section">
      <Select
        v-model="fieldsFilter.actualExploitationIds"
        label="Фактическая эксплуатация"
        :options="sortOptions(actualExploitations, fieldsFilter.actualExploitationIds)"
        :isSort="true"
        :clearable="true"
        :allable="true"
        :multiple="true"
      />
    </div>
    <div v-access="'fields_filter_complexity_exploitation_ids'" class="filter__section">
      <Select
        v-model="fieldsFilter.complexityExploitationIds"
        label="Сложность ввода в эксплуатацию"
        :options="sortOptions(complexityExploitation, fieldsFilter.complexityExploitationIds)"
        :isSort="true"
        :clearable="true"
        :allable="true"
        :multiple="true"
      />
    </div>
    <div v-access="'fields_filter_efis_data_unused_land_group_id'" class="filter__section">
      <Select
        v-model="fieldsFilter.efisDataUnusedLandGroupId"
        label="Состояние неиспользуемых земель"
        :options="efisDataUnusedLandGroups"
        :clearable="true"
      />
    </div>
    <div v-access="'fields_filter_operation_year_ids'" class="filter__section">
      <Select
        v-model="fieldsFilter.operationYearIds"
        label="Год ввода в оборот"
        :options="operationYears"
        :clearable="true"
        :multiple="true"
      />
    </div>
    <div v-access="'fields_filter_fact_users'" class="filter__section native-input">
      <span class="label-select">Фактический пользователь</span>
      <div class="relative">
        <OrgsSelect
          v-model="fieldsFilter.factUsers"
          :clearable="true"
          :multiple="true"
          :selectedOrgs="fieldsFilter.factUsers"
        />
        <q-badge v-if="fieldsFilter.factUsers.length > 1" color="accent" floating rounded >
          {{ fieldsFilter.factUsers.length }}
        </q-badge>
      </div>
    </div>
    <div v-access="'fields_filter_field_land_type_ids'" class="filter__section">
      <Select
        v-model="fieldsFilter.fieldLandTypeIds"
        label="Вид с/х угодий"
        :options="sortOptions(landTypes, fieldsFilter.fieldLandTypeIds)"
        :isSort="true"
        :clearable="true"
        :allable="true"
        :multiple="true"
      />
    </div>
    <div v-access="'fields_filter_culture_type_ids'" class="filter__section native-input" @click="fetchCultures">
      <Select
        v-model="fieldsFilter.cultureTypeIds"
        ref="cultureTypeIdsRef"
        label="Вид культуры"
        :options="sortOptions(cultureOptions, fieldsFilter.cultureTypeIds)"
        :clearable="true"
        :allable="true"
        :multiple="true"
        :scrollSize="300"
        :fieldLandTypeIds="fieldsFilter.fieldLandTypeIds"
        :isSort="true"
        :use-input="true"
        filterType="culture"
      />
    </div>
    <div v-access="'fields_filter_culture_type_ids'" class="filter__section native-input" @click="fetchCultures">
      <Select
        v-model="fieldsFilter.efisOkpd2Ids"
        ref="efisOkpd2IdsRef"
        label="Код культуры по ОКПД 2"
        :options="sortOptions(cultureOkpd2Options, fieldsFilter.efisOkpd2Ids)"
        :clearable="true"
        :multiple="true"
        :scrollSize="700"
        :isSort="true"
        :use-input="true"
      />
    </div>
    <div v-access="'fields_filter_efis_data_culture_rotation_id'" class="filter__section">
      <Select
        v-model="fieldsFilter.efisDataCultureRotationId"
        label="Тип севооборота"
        :options="efisDataCultureRotation"
        :clearable="true"
      />
    </div>
    <div v-access="'fields_filter_efis_veg_type_id'" class="filter__section">
      <Select
        v-model="fieldsFilter.efisVegTypeId"
        label="Видовой состав растительного покрова"
        :options="efisVegTypes"
        :clearable="true"
      />
    </div>
    <div v-access="'fields_filter_efis_microbio_id'" class="filter__section">
      <Select
        v-model="fieldsFilter.efisMicrobioId"
        label="Биологическая активность"
        :options="efisMicrobioTypes"
        :clearable="true"
      />
    </div>
    <div v-access="'fields_filter_efis_gp'" class="filter__section">
      <Select
        v-model="fieldsFilter.efisGp"
        label="Информация о госпрограмме"
        :options="efisGpTypes"
        :clearable="true"
      />
    </div>
    <div v-access="'fields_filter_efis_rec_use_id'" class="filter__section">
      <Select
        v-model="fieldsFilter.efisRecUseId"
        label="Рекомендации по использованию земельного участка"
        :options="efisRecUseTypes"
        :clearable="true"
      />
    </div>
    <div v-access="'fields_filter_grass_perennial'" class="filter__section">
      <Select
        v-model="fieldsFilter.grassPerennial"
        label="Многолетние травы"
        :options="booleanOptions"
        :clearable="true"
      />
    </div>
    <div v-access="'fields_filter_efis_year_use_id'" class="filter__section">
      <Select
        v-model="fieldsFilter.efisYearUseId"
        label="Год пользования  (для многолетних трав)"
        :options="efisYearUseTypes"
        :clearable="true"
      />
    </div>
    <div v-access="'fields_filter_siderate'" class="filter__section">
      <Select
        v-model="fieldsFilter.siderate"
        label="Сидерат"
        :options="booleanOptions"
        :clearable="true"
      />
    </div>
    <div v-access="'fields_filter_grass_cov'" class="filter__section">
      <Select
        v-model="fieldsFilter.grassCov"
        label="Подпокровные травы"
        :options="booleanOptions"
        :clearable="true"
      />
    </div>
    <div v-access="'fields_filter_land_cover_analysis'" class="filter__section">
      <Select
        v-model="fieldsFilter.landCoverAnalysis"
        label="Анализ земного покрова"
        :options="landCoverTypes"
      />
    </div>
    <div v-access="'fields_filter_check_status_id'" class="filter__section">
      <Select
        v-model="fieldsFilter.checkStatusId"
        label="Статус проверки"
        :options="optionsCheckStatus"
        :clearable="true"
      />
    </div>
    <div class="filter__section">
      <Select
        v-model="fieldsFilter.hasSection"
        label="Расположено на участке"
        :options="hasSectionStatus"
      />
    </div>
    <div v-access="'fields_filter_deleted'" class="filter__section">
      <Select
        v-model="fieldsFilter.deleted"
        label="Удаленные/неудаленные"
        :options="deletedStatus"
      />
    </div>
  </template>
</q-form>
</template>
<script setup>
import { defineEmits, watch, ref, reactive, computed, onMounted, defineExpose } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useQuasar } from 'quasar'
import { cloneDeep } from 'lodash'
import Select from '@/components/common/selects/Select'
import OrgsSelect from '@/components/common/selects/OrgsSelect'

const store = useStore()
const router = useRouter()
const route = useRoute()
const $q = useQuasar()

const emits = defineEmits(['setFilter', 'setIsLoad'])

const cultureTypeIdsRef = ref(null)
const cultureOptions = ref([])
const filterType = ref('map') // 'user', 'map'

const hasSectionStatus = [
  { label: 'Да', value: true },
  { label: 'Нет', value: false },
  { label: 'Все', value: null }
]

const user = computed(() => store.state.auth.user)
const view = computed(() => store.state.map.view)
const visibleEntity = computed(() => store.state.map.visibleEntity)
const editFieldCount = computed(() => store.state.mapFilter.editFieldCount)
const countWinterFilter = computed(() => store.state.map.countWinterFilter)
const countSummerFilter = computed(() => store.state.map.countSummerFilter)
const appliedFieldsFilter = computed(() => store.state.mapFilter.fieldsFilter)

const municipalities = computed(() => store.state.dictionaries.regions)
const editCount = computed(() => store.state.mapFilter.editCount)

const optionsCheckStatus = computed(() => store.state.dictionaries.checkStatuses)
const deletedStatus = computed(() => store.state.dictionaries.deletedStatus)
const actualExploitations = computed(() => store.state.dictionaries.actualExploitations.filter((category) => !category.isHidden))
const complexityExploitation = computed(() => store.state.dictionaries.complexityExploitation.filter((category) => !category.isHidden))
const landTypes = computed(() => store.state.dictionaries.fieldLandTypes.filter((category) => !category.isHidden))
const cultureTypes = computed(() => store.state.dictionaries.typeCulture.filter((category) => !category.isHidden))
const periods = computed(() => store.state.dictionaries.periods)
const currentPeriod = computed(() => periods.value.filter((period) => period.isNow))
const activeWinterPeriod = computed(() => periods.value.find((period) => period.active && period.isWinter))
const activeSummerPeriod = computed(() => periods.value.find((period) => period.active && !period.isWinter))
const operationYears = computed(() => store.state.dictionaries.operationYears.filter((item) => !item.isHidden))
const landCoverTypes = computed(() => store.state.dictionaries.landCoverAnalysis.concat([{ label: 'Все', value: null }]))
const efisDataCultureRotation = computed(() => store.state.dictionaries.efisDataCultureRotation.filter((category) => !category.isHidden))
const efisDataUnusedLandGroups = computed(() => store.state.dictionaries.efisDataUnusedLandGroups.filter((category) => !category.isHidden))
const orgsByIds = computed(() => store.state.orgs.orgsByIds?.filter((org) => !org.isHidden))
const efisVegTypes = computed(() => store.state.dictionaries.efisVegType.filter((category) => !category.isHidden))
const efisGpTypes = computed(() => store.state.dictionaries.efisGp.filter((category) => !category.isHidden))
const efisMicrobioTypes = computed(() => store.state.dictionaries.efisMicrobio.filter((category) => !category.isHidden))
const efisRecUseTypes = computed(() => store.state.dictionaries.efisRecUse.filter((category) => !category.isHidden))
const efisYearUseTypes = computed(() => store.state.dictionaries.efisYearUse.filter((category) => !category.isHidden))

const booleanOptions = [
  { name: 'Нет', label: 'Нет', value: false },
  { name: 'Да', label: 'Да', value: true },
  { name: 'Все', label: 'Все', value: null }
]

const cultureOkpd2Options = computed(() => {
  const options = []

  const getOption = (item) => {
    const option = { name: item.name, value: item.id, label: item.name, cannotSelect: item.allChildren?.length > 0 }
    options.push(option)

    if (item.allChildren?.length) {
      item.allChildren.forEach(getOption)
    }
  }

  store.state.dictionaries.efisOkpd2.filter((category) => !category.isHidden).forEach(getOption)

  return options
})

if (cultureTypes.value?.length) {
  cultureOptions.value = cultureTypes.value
}

const sortOptions = (options, filterValue) => options?.filter((item) => !item.value)
  .concat(options.filter((item) => filterValue.includes(item.value)))
  .concat(options.filter((item) => item.value && !filterValue.includes(item.value)))

const fieldsFilterInitValues = {
  regionIds: [],
  periodIds: [],
  fieldId: '',
  fieldAreaMin: '0',
  fieldAreaMax: null,
  // harvMin: '0',
  // harvMax: null,
  // harvGrossMin: '0',
  // harvGrossMax: null,
  actualExploitationIds: [],
  complexityExploitationIds: [],
  factUsers: [],
  fieldLandTypeIds: [],
  cultureTypeIds: [],
  efisOkpd2Ids: [],
  checkStatusId: null,
  deleted: 'hide',
  operationYearIds: [],
  efisDataCultureRotationId: null,
  efisDataUnusedLandGroupId: null,
  landCoverAnalysis: null,
  hasSection: null,
  efisNumber: '',
  efisRecUseId: null,
  efisYearUseId: null,
  efisGp: null,
  efisVegTypeId: null,
  efisMicrobioId: null,
  siderate: null,
  grassCov: null,
  grassPerennial: null,
  page: 1
}

const fieldsFilter = reactive(cloneDeep(fieldsFilterInitValues))

const setRegionValue = () => {
  fieldsFilter.regionIds = user.value.region?.value ? [Number(user.value.region.value)] : fieldsFilter.regionIds
}

const fetchCultures = () => {
  if (cultureTypes.value.length < 1) {
    cultureTypeIdsRef.value?.blur()
    store.dispatch('dictionaries/fetchTypeCulture')
      .then(() => {
        cultureTypeIdsRef.value?.showPopup()
      })
  }
}

const setInitialFieldFilter = (fieldId) => {
  const values = cloneDeep(fieldsFilterInitValues)
  Object.keys(values).forEach((key) => {
    fieldsFilter[key] = values[key]
  })

  setRegionValue()
  fieldsFilter.periodIds = [currentPeriod.value[0]?.id]
  fieldsFilter.fieldId = fieldId ?? ''
}

const clearFieldFilter = () => {
  filterType.value = 'user'
  setInitialFieldFilter()
  fieldsFilter.page = 1
  initFilter()
}

const getFilter = () => {
  let fields = {}

  const boolKeys = [
    'hasSection',
    'siderate',
    'grassCov',
    'grassPerennial'
  ]

  for (let key in fieldsFilter) {
    if (Object.keys(fieldsFilterInitValues).includes(key)) {
      if (Array.isArray(fieldsFilter[key]) && fieldsFilter[key].length > 0) {
        fields[key] = fieldsFilter[key]
      } else if (boolKeys.includes(key) && (fieldsFilter[key] === true || fieldsFilter[key] === false)) {
        fields[key] = fieldsFilter[key]
      } else if (!Array.isArray(fieldsFilter[key]) && fieldsFilter[key]) {
        fields[key] = key === 'fieldAreaMin' || key === 'fieldAreaMax' ? Number(fieldsFilter[key]) : fieldsFilter[key]
      }
    }
  }

  return {
    fields
  }
}

const applyFilter = async (hash) => {
  filterType.value = 'user'
  store.dispatch('settings/fetchMaintenance')
  store.commit('map/setMapState', 'static')
  emits('setIsLoad', true)
  setRegionValue()

  const { fields } = getFilter()
  store.commit('mapFilter/setFieldsFilter', fieldsFilter?.periodIds?.length > 0 ? fields : {})
  store.commit('fields/setPeriod', fieldsFilter?.periodIds ? fieldsFilter.periodIds : [])

  store.dispatch('mapFilter/fetchFieldsByFilter', {
    ...fields,
    type: filterType.value
  })
    .then(() => {
      emits('setIsLoad', false)
    })
    .catch((error) => {
      emits('setIsLoad', false)
      store.dispatch('auth/fetchUser')

      const errorText = error?.response?.data?.message
      $q.notify({
        type: 'negative',
        position: 'bottom',
        message: errorText ? errorText : 'Ошибка',
        timeout: 5000
      })
    })


  if ((hash === '#table' || view.value === 'table') && (visibleEntity.value === 'sections' || (visibleEntity.value === 'fields' && fieldsFilter.periodIds.length > 0))) {
    applyPaginatedFilter()
  }

  emits('setFilter', fieldsFilter)
  store.commit('mapFilter/setFilterCount')
}

const applyPaginatedFilter = () => {
  store.dispatch('mapFilter/fetchPaginatedFields', {
    ...appliedFieldsFilter.value,
    page: fieldsFilter.page,
    searchType: 'fields',
    perPage: 20
  })
    .then((res) => {
      const lastPage = res.meta?.lastPage
      const currentPage = fieldsFilter.page

      emits('setIsLoad', false)

      if (view.value === 'table' && Number.isInteger(lastPage) && Number.isInteger(currentPage) && (currentPage > lastPage)) {
        fieldsFilter.page = 1
        router.push({ to: 'map', query: { ...route.query, page: 1 }, hash: '#table' }).catch(() => {})
        emits('setIsLoad', true)
      }
    })
    .catch((error) => {
      emits('setIsLoad', false)
      store.dispatch('auth/fetchUser')

      const errorText = error?.response?.data?.message
      $q.notify({
        type: 'negative',
        position: 'bottom',
        message: errorText ? errorText : 'Ошибка',
        timeout: 5000
      })
    })
}

const applyWinterFilter = () => {
  setInitialFieldFilter()
  fieldsFilter.periodIds = [activeWinterPeriod.value?.id]
  fieldsFilter.actualExploitationIds = [8]
  fieldsFilter.fieldLandTypeIds = [1]
  fieldsFilter.cultureTypeIds = cultureOptions.value
    .filter((item) => item.isWinter)
    .map((item) => item.value)

  fieldsFilter.page = 1
  applyFilter()
}

const applySummerFilter = () => {
  setInitialFieldFilter()

  fieldsFilter.periodIds = [activeSummerPeriod.value?.id]
  fieldsFilter.actualExploitationIds = [8, 9]
  fieldsFilter.fieldLandTypeIds = landTypes.value
    .filter((type) => type.name !== 'Нет данных' && type.name !== 'Не указывается')
    .map((item) => item.value)

  fieldsFilter.page = 1

  applyFilter()
}

const onLoadPeriods = () => {
  fieldsFilter.periodIds = [currentPeriod.value[0]?.id]

  // if (periods.value.length) {
  //   if (user.value.role === 'apkmunicipalitet' && !route.params.id) {
  //     fieldsFilter.periodIds = [currentPeriod.value?.length === 1 ? currentPeriod.value[0]?.id : CURRENT_PERIOD]
  //     applyFilter()
  //   }
  // }

  const factUsers = route.query?.factUsers
  const periodIds = route.query?.periodIds
  if (factUsers) {
    let periodsArray
    if (Array.isArray(periodIds)) {
      periodsArray = periodIds.map((id) => Number(id))
    }

    if (periodIds && typeof periodIds === 'string') {
      periodsArray = [+periodIds]
    }

    setInitialFieldFilter()

    let defaultPeriods = []
    if (activeSummerPeriod.value?.id) {
      defaultPeriods.push(activeSummerPeriod.value?.id)
    }

    if (activeWinterPeriod.value?.id) {
      defaultPeriods.push(activeWinterPeriod.value?.id)
    }

    fieldsFilter.periodIds = periodsArray ?? defaultPeriods
    fieldsFilter.factUsers = [+factUsers]
  }

  const fieldId = route.query?.field

  if (fieldId) {
    setInitialFieldFilter(fieldId)
    setTimeout(() => {
      store.dispatch('fields/fetchTileField', Number(fieldId))
        // .then((response) => {
        //   const hash = response?.sections?.main[0]?.hash
        //   if (hash) {
        //     store.dispatch('fields/fetchSectionGeo', hash)
        //   }
        // })
        .catch((err) => {
          console.log(err)
        })
    }, 2000)
  }

  if (localStorage.getItem('field')) {
    const field = JSON.parse(localStorage.getItem('field'))

    Object.keys(field.filter.fields).forEach((key) => {
      fieldsFilter[key] = field.filter.fields[key]
    })

    setTimeout(() => {
      store.dispatch('fields/fetchTileField', field.id)
      localStorage.removeItem('field')
    }, 2000)
  }

  if (user.value?.name !== 'demo' || fieldId) {
    applyFilter()
  }

  const hash = route.hash
  if (hash === 'table') {
    applyPaginatedFilter()
  }

  const { fields } = getFilter()
  store.commit('mapFilter/setFieldsFilter', fields)
  store.commit('fields/setPeriod', fieldsFilter.periodIds)
}

const initFilter = async () => {
  const hash = route.hash

  if (route.params.id) {
    await router.push({ path: '/map', params: {}, hash }).catch(() => {})
  }

  applyFilter(hash)
}

watch(editCount, () => {
  const { fields } = getFilter()
  store.commit('mapFilter/setFieldsFilter', fields)
  const hash = route.hash
  applyFilter(hash)
})

watch(currentPeriod, (value, oldValue) => {
  if (value[0]?.id && value[0]?.id !== oldValue[0]?.id) {
    onLoadPeriods()
  }
})

watch(view, (value) => {
  if (value === 'table') {
    applyPaginatedFilter()
  }
})

watch(() => route.query.page, (value) => {
  const hash = route.hash

  fieldsFilter.page = value && Number.isInteger(Number(value)) ? Number(value) : 1
  if (hash === '#table' || view.value === 'table') {
    applyPaginatedFilter()
  }
})

watch(() => fieldsFilter.factUsers, (value) => {
  const orgs = value.filter((org) => !orgsByIds.value.map((org) => org.id).includes(org))

  if (orgs?.flat().length > 0) {
    store.dispatch('orgs/fetchOrgs', orgs?.flat())
  }
})

watch(visibleEntity, (value) => {
  if (view.value === 'table') {
    store.commit('mapFilter/setAreasByFilter', [])

    if (value === 'fields' && fieldsFilter.periodIds.length > 0) {
      const currentPage = route.query.page
      if (currentPage !== '1') {
        fieldsFilter.page = 1
      }
      applyPaginatedFilter()
    }
  }
})

watch(countSummerFilter, () => {
  applySummerFilter()
})

watch(countWinterFilter, () => {
  applyWinterFilter()
})

watch(editFieldCount, () => {
  const hash = route.hash
  applyFilter(hash)
})

onMounted(() => {
  if (currentPeriod.value[0]?.id) {
    onLoadPeriods()
  }
})

defineExpose({ applyFilter, clearFieldFilter })
</script>
<style lang="scss" scoped>
.filter {
  width: 100%;
  max-width: 344px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &__form {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    flex-grow: 1;
    position: relative;
    padding: 16px 30px 16px 30px;
    height: calc(100% - 40px - 59px);
    // height: 100%;

    overflow-y: auto;
    overflow-x: hidden;
  }

  // &__footer {
  //   // position: absolute;
  //   // bottom: 0;
  //   width: 100%;
  // }

  // &__info-container {
  //   display: flex;
  // }

  // &__info {
  //   border-top: 1px solid $color-gray3;
  //   // position: absolute;
  //   padding: 5px 25px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   min-height: 59px;
  //   // bottom: 40px;
  //   width: 100%;

  //   &--fields {
  //     width: 50%;
  //     flex-direction: column;
  //     padding: 10px;

  //     &:last-child {
  //       border-left: 1px solid $color-gray3;
  //     }
  //   }
  // }

  .tooltip-label {
    position: relative;
    display: inline-block;
    font-weight: 500;
  }

  &__section {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &--validate:not(:last-child) {
      margin-bottom: 0;
    }

    &--validate:has(.q-field--error) {
      margin-bottom: 10px;
    }

    &--checkbox {
      margin-left: -10px;
    }

    &_flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .input {
        width: calc(50% - 6px);
      }
    }
  }

  .button {
    width: 100%;
    border-radius: 0;
  }

  &__entity {
    position: relative;
    padding: 15px 0;

    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   left: -30px;
    //   right: -45px;
    //   height: 1px;
    //   background-color: $color-gray3;
    // }

    // &--coords::after {
    //   display: none;
    // }
  }

  &__toggle-entity {
    width: 100%;
    background-color: transparent;
    border: none;
    text-decoration: underline dotted;
    color: $color-active;
    cursor: pointer;
  }

  &__clear-filter {
    background-color: transparent;
    border: none;
    color: $color-active;
    cursor: pointer;
  }
}

.label {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 16px;
}
</style>
