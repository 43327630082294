export const zonesRr = [
  { name: 'Охранная зона инженерных коммуникаций', color: '#00BFFF', typeCode: 99618844 },
  { name: 'Охранная зона линий и сооружений связи и линий и сооружений радиофикации', color: '#02dde0', typeCode: '6c25b94f' },
  { name: 'Прибрежная защитная полоса', color: '#FFD700', typeCode: 'b0d61e4a' },
  { name: 'Придорожная полоса', color: '#F4A460', typeCode: 'c23f7e78' },
  { name: 'Водоохранная зона', color: '#7FFFD4', typeCode: '09b184a6' },
  { name: 'Санитарно-защитная зона предприятий, сооружений и иных объектов', color: '#6495ED', typeCode: '081bfec8' },
  { name: 'Жилая зона', color: '#FFA07A', typeCode: '750553e8' },
  { name: 'Иная зона', color: '#98FB98', typeCode: '70fe7932' },
  { name: 'Зона специального назначения', color: '#DC143C', typeCode: '44b09f2b' },
  { name: 'Зеленая зона', color: '#6B8E23', typeCode: '26060c4d' },
  { name: 'Зона публичного сервитута', color: '#DEB887', typeCode: '028de651' },
  { name: 'Территория особо охраняемого природного объекта', color: '#90EE90', typeCode: 'd854d650' },
  { name: 'Иные зоны с особыми условиями использования территории', color: '#8B0000', typeCode: '006a9303' },
  { name: 'Зона охраны объекта культурного наследия', color: '#FF00FF', typeCode: 'fb981fdd' },
  { name: 'Зона ограничения от передающего радиотехнического объекта' , color: '#FFFF00', typeCode: 'c794485b' },
  { name: 'Зона особо охраняемых территорий', color: '#FFFF00', typeCode: '0f71f9f6' },
]

const officialLayers = [
  {
    type: 'farm',
    group: 'official',
    name: 'pkk_data',
    label: 'ПКК',
    value: 'pkk',
    options: {
      type: 'raster',
      tiles: [
        'https://gext.ru/rosreestr_xyz/{z}/{x}/{y}.png',
      ]
    },
    layers: [
      {
        id: 'pkk',
        type: 'raster',
        source: 'pkk_data',
        'source-layer': 'pkk_data',
        layout: {
          'visibility': 'none'
        },
        minzoom: 14,
        maxzoom: 17,
        paint: {
          'raster-opacity': 1
        }
      },
    ]
  },
  {
    type: 'non-farm',
    group: 'official',
    name: 'pkk_zones_data',
    label: 'ЗОУИТ',
    value: 'pkk_zones',
    options: {
      type: 'raster',
      tiles: [
        'https://gext.ru/rosreestr_zones_xyz/{z}/{x}/{y}.png',
      ]
    },
    layers: [
      {
        id: 'pkk_zones',
        type: 'raster',
        source: 'pkk_zones_data',
        'source-layer': 'pkk_zones_data',
        layout: {
          'visibility': 'none'
        },
        minzoom: 14,
        maxzoom: 17,
        paint: {
          'raster-opacity': 1
        }
      },
    ]
  },
  {
    type: 'non-farm',
    group: 'official',
    name: 'zones_data',
    label: 'Особо охраняемые природные территории',
    value: 'zones',
    options: {
      type: 'raster',
      tiles: [
        'https://lgtgis.aari.ru/arcgis/services/MCPA/PAWMS_lite/MapServer/WMSServer/?service=wms&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&format=image/png&layers=0,2,3&styles=&transparent=true&tiled=true&bbox={bbox-epsg-3857}',
      ]
    },
    layers: [
      {
        id: 'zones',
        type: 'raster',
        source: 'zones_data',
        'source-layer': 'zones_data',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'raster-opacity': 1
        }
      },
    ]
  },
  {
    type: 'non-farm',
    group: 'official',
    name: 'zones',
    label: 'Зоны с особыми условиями использования',
    value: 'zones_rr',
    options: {
      type: 'vector',
      tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/zones/{z}/{x}/{y}.mvt']
    },
    layers: [{
      id: 'zones_fill',
      type: 'fill',
      source: 'zones',
      'source-layer': 'zones',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'fill-color': [
          'match',
          ['get', 'TYPE']]
          .concat(zonesRr.map((zone) => [[zone.name], zone.color]).flat())
          .concat(['#FDDFDF']),
        'fill-opacity': 0.6,
      },
    }]
  },
  {
    type: 'farm',
    group: 'official',
    name: 'municipals_s',
    label: 'Границы муниципалитетов',
    value: 'municipals_fill',
    icon: 'municipals',
    options: {
      type: 'vector',
      tiles: [(process.env.VUE_APP_GEOSERVER_API_URL || 'https://geo.sovhoz.tech/api') + '/mapbox/municipals/{z}/{x}/{y}.mvt']
    },
    layers: [{
      id: 'municipals_fill',
      type: 'line',
      source: 'municipals_s',
      'source-layer': 'municipals',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'line-color': '#f25749',
      },
    },]
  },
]

export { officialLayers }
