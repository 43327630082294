<template>
  <q-form ref="formRef">
    <div class="select">
      <Select
        placeholder="Использовать атрибуты из поля"
        v-model="fieldId"
        :options="ids"
        :rules="[val => !!val || 'Обязательное поле']"
      />
    </div>
  </q-form>
</template>
<script setup>
import { ref, computed, defineEmits, defineExpose } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import Select from '@/components/common/selects/Select'
import ApiService from '@/services'

const store = useStore()
const $q = useQuasar()

const emits = defineEmits(['close'])

const formRef = ref()
const fieldId = ref()

const joiningFields = computed(() => store.state.fields.joiningFields)
const joiningFieldsPolygon = computed(() => store.state.fields.joiningFieldsPolygon)
const ids = computed(() => joiningFields.value.map((field) => field.id))

const joinFields = () => {
  const formEl = formRef.value
  formEl.validate().then(async (result) => {
    if (result && fieldId.value && joiningFieldsPolygon.value?.geometry && joiningFieldsPolygon.value?.area) {
      store.commit('modals/setDisableModal', true)
      try {
        const { data } = await ApiService.fields.checkFieldPolygonOverlaps({
          geometry: joiningFieldsPolygon.value?.geometry,
          id: null
        })

        const overlaps = data?.fields?.filter((overlap) => !ids.value?.includes(overlap.overlapsField?.id))

        const layerNames = {
          'lesnoyfond': 'Лесной фонд',
          'zemlipromyshlen': 'Земли промышленности'
        }

        let otherLayersOverlaps = []

        otherLayersOverlaps = data.layers?.map((overlap) => ({
          ...overlap,
          layer: layerNames[overlap.overlapSegmentLayer],
          geometry: overlap.overlapGeometry ?? null
        }))

        if (overlaps?.length > 0 || otherLayersOverlaps?.length > 0) {
          store.commit('fields/setFieldOverlaps', overlaps)
          store.commit('fields/setFieldOtherLayersOverlaps', otherLayersOverlaps)
          store.commit('modals/setIsFieldOverlapsModal', true)
        }

        if (overlaps.length < 1 && otherLayersOverlaps?.length < 1 && joiningFieldsPolygon.value.geometry?.geometry?.type === 'Polygon') {
          await ApiService.fields.joinFields({
            update: {
              id: fieldId.value,
              geometry: joiningFieldsPolygon.value.geometry,
              area: joiningFieldsPolygon.value.area
            },
            delete: ids.value.filter((id) => id !== fieldId.value)
          })

          store.commit('mapFilter/setEditCount')
          store.commit('modals/setIsShowJoinFieldsModal', false)
          store.commit('map/setMode', 'default')
          // emits('close')
          $q.notify({
            type: 'positive',
            position: 'bottom',
            message: 'Успешно',
            timeout: 5000
          })
        } else if (joiningFieldsPolygon.value.geometry?.geometry?.type !== 'Polygon') {
          store.commit('modals/setIsShowJoinFieldsModal', false)

          $q.notify({
            type: 'negative',
            position: 'bottom',
            message: 'Поле не должно быть мультиполигоном',
            timeout: 5000
          })
        } else {
          store.commit('fields/setFieldOverlaps', overlaps)
          store.commit('fields/setFieldOtherLayersOverlaps', otherLayersOverlaps)
          store.commit('modals/setIsFieldOverlapsModal', true)
          store.commit('modals/setIsShowJoinFieldsModal', false)
        }
      } catch (error) {
        const errorText = error?.response?.data?.message
        $q.notify({
          type: 'negative',
          position: 'bottom',
          message: errorText ? errorText : 'Ошибка',
          timeout: 5000
        })
      } finally {
        store.commit('modals/setDisableModal', false)
      }
    }
  })
}

defineExpose({ joinFields })
</script>
<style lang="scss" scoped>
.select {
  margin-top: 24px;
  width: 80vw;
  max-width: 280px;
}
</style>
