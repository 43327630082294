<template>
  <div>
    <SchemeControls v-if="mode === 'create-scheme' && geometryScheme" />

    <SchemeDetails v-if="currentScheme" />
  </div>
</template>
<script setup>
import { defineProps, defineEmits, computed, watch, onMounted, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import * as Turf from '@turf/turf'
import localforage from 'localforage'
import SchemeControls from '@/modules/map/components/controls/SchemeControls'
import SchemeDetails from '@/modules/map/components/SchemeDetails'
import { addCreatedLayer, removeCreatedLayer } from '../utils/layers/created-layer'
import { addKugiSchemesLayer, removeKugiSchemesLayer } from '@/modules/map/utils/layers/kugi-schemes-layer'
import source from '@/modules/map/layers/schemes'
import ApiService from '@/services'

const props = defineProps(['mode', 'map'])
const emits = defineEmits(['onClickScheme'])

const hoveredStateScheme = ref(null)

const store = useStore()
const geometryScheme = computed(() => store.state.schemes.geometryScheme)
const currentScheme = computed(() => store.state.schemes.currentScheme)
// const isVisibleSchemeLayer = computed(() => store.state.schemes.isVisibleSchemeLayer)
const isVisibleSchemeLayer = computed(() => store.state.map.visibleEntity === 'schemes')
const schemesStorage = computed(() => store.state.schemes.schemesStorage)
const deletedSchemes = computed(() => store.state.schemes.deletedSchemes)
const cacheLifeTime = computed(() => store.state.map.cacheLifeTime)
const activeScheme = computed(() => store.state.schemes.activeScheme)
const countEditedScheme = computed(() => store.state.schemes.countEditedScheme)
const schemes = computed(() => store.state.schemes.schemes)

const onClickScheme = (e) => {
  emits('onClickScheme', e)
}

const uploadSchemePolygon = (geometry) => {
  if (props.map && geometry?.coordinates?.length) {
    const createdPolygon = [
      {
        id: '123456789',
        properties: {
          name: 'load',
          hash: '',
          CN: '',
        },
        geometry,
      },
    ]

    removeCreatedLayer(props.map)

    addCreatedLayer({
      polygons: createdPolygon,
      map: props.map,
      color: '#ff00a6',
      activeColor: '#dc1c0a',
    })

    store.commit('modals/setIsShowUploadSchemeModal', false)

    props.map.fitBounds(Turf.bbox(createdPolygon[0].geometry), {
      maxZoom: 12,
    })
  }
}

const mouseMoveScheme = (e) => {
  if (e.features.length > 0) {
    if (hoveredStateScheme.value) {
      props.map.setFeatureState(
        {
          source: e.features[0].source,
          sourceLayer: e.features[0].sourceLayer,
          id: hoveredStateScheme.value.id,
        },
        { hover: false }
      )
    }
    hoveredStateScheme.value = e.features[0]
    props.map.setFeatureState(
      {
        source: hoveredStateScheme.value?.source,
        sourceLayer: hoveredStateScheme.value?.sourceLayer,
        id: hoveredStateScheme.value?.id,
      },
      { hover: true }
    )
  }
}
const mouseLeaveScheme = () => {
  if (hoveredStateScheme.value) {
    props.map.setFeatureState(
      {
        source: hoveredStateScheme.value.source,
        sourceLayer: hoveredStateScheme.value.sourceLayer,
        id: hoveredStateScheme.value.id,
      },
      { hover: false }
    )
  }
  hoveredStateScheme.value = null
}

const setFilterScheme = () => {
  if (props.map.getLayer('sh_kugi_fill')) {
    // props.map.setFilter('sh_kugi_fill', ['==', ['get', 'deleted'], false])
    props.map.setFilter('sh_kugi_fill', [
      'all',
      [
        'match',
        ['get', 'id'],
        schemes.value?.length > 0
          ? Array.from(new Set(schemes.value.map((scheme) => scheme.id)))
          : ['a'],
        true,
        false,
      ],
    ])
  }

  if (props.map.getLayer('sh_kugi_line')) {
    // props.map.setFilter('sh_kugi_line', ['==', ['get', 'deleted'], false])
    props.map.setFilter('sh_kugi_line', [
      'all',
      [
        'match',
        ['get', 'id'],
        schemes.value?.length > 0
          ? Array.from(new Set(schemes.value.map((scheme) => scheme.id)))
          : ['a'],
        true,
        false,
      ],
    ])
  }

  if (props.map.getLayer('kugi_scheme_fill')) {
    props.map.setFilter('kugi_scheme_fill', [
      'all',
      [
        'match',
        ['get', 'back_id'],
        schemes.value?.length > 0
          ? Array.from(new Set(schemes.value.map((scheme) => scheme.id)))
          : ['a'],
        true,
        false,
      ],
    ])
  }

  if (props.map.getLayer('kugi_scheme_line')) {
    props.map.setFilter('kugi_scheme_line', [
      'all',
      [
        'match',
        ['get', 'back_id'],
        schemes.value?.length > 0
          ? Array.from(new Set(schemes.value.map((scheme) => scheme.id)))
          : ['a'],
        true,
        false,
      ],
    ])
  }
}

const repaintSchemesFromStorage = ({ polygons, map }) => {
  removeKugiSchemesLayer(map)
  map.off('click', 'kugi_scheme_fill', onClickScheme)
  map.off('touchstart', 'kugi_scheme_fill', onClickScheme)
  map.off('mousemove', 'kugi_scheme_fill', mouseMoveScheme)
  map.off('mouseleave', 'kugi_scheme_fill', mouseLeaveScheme)
  map.off('moveend', repaintHandler)
  map.off('zoomend', repaintHandler)

  if (polygons.length > 0) {
    addKugiSchemesLayer({
      polygons,
      map
    })
    map.on('click', 'kugi_scheme_fill', onClickScheme)
    map.on('touchstart', 'kugi_scheme_fill', onClickScheme)
    map.on('mousemove', 'kugi_scheme_fill', mouseMoveScheme)
    map.on('mouseleave', 'kugi_scheme_fill', mouseLeaveScheme)
    map.on('moveend', repaintHandler)
    map.on('zoomend', repaintHandler)

    polygons.forEach((polygon) => {
      setStateSchemeKugi(polygon.id, { active: false })
    })

    const activeSchemes = polygons.filter((polygon) => polygon.id === activeScheme.value?.id)

    if (activeSchemes.length > 0) {
      setStateSchemeKugi(activeSchemes[0].id, { active: true })
    }
  }
}

const repaintHandler = () => {
  const layers = ['sh_kugi']

  const ids = schemesStorage.value.map((scheme) => scheme.properties.back_id)
  const deletedIds = deletedSchemes.value.map((scheme) => scheme.id)
  let renderedSchemes = []
  layers.forEach((layer) => {
    if (props.map.getSource(layer)) {
      renderedSchemes = [
        ...renderedSchemes,
        ...props.map.querySourceFeatures(layer, { sourceLayer: layer })
          .filter((polygon) => ids.includes(polygon.properties.back_id) || deletedIds.includes(polygon.properties.back_id))
          .map((polygon) => ({ ...polygon, layer }))
      ]
    }
  })
  renderedSchemes.forEach((polygon) => {
    props.map.setFeatureState(
      { source: polygon.layer, sourceLayer: polygon.layer, id: polygon.id },
      { active: false, hover: false, edited: true }
    )
  })
}

const setStateSchemeKugi = (id, state) => {
  if (props.map.getSource('kugi_scheme_data')) {
    props.map.setFeatureState(
      {
        source: 'kugi_scheme_data',
        id,
      },
      state
    )
  }
}

onMounted(() => {
  source.forEach((source) => {

    if (!props.map.getSource(source.name)) {
      props.map.addSource(source.name, source.options)
    }

    source.layers.forEach((layer) => {
      props.map.addLayer(layer)
      props.map.on('mousemove', layer.id, mouseMoveScheme)
      props.map.on('mouseleave', layer.id, mouseLeaveScheme)
      props.map.on('click', layer.id, onClickScheme)
    })
  }
  )


  localforage.getItem('schemes')
    .then((schemes) => {
      if (schemes && schemes.length > 0) {
        const schemesStorage = schemes.filter((scheme) => {
          return Date.now() - scheme.createdAt < cacheLifeTime.value * 1000
        })
        store.commit('schemes/setSchemesStorage', schemesStorage)
      }
    })
    .catch((error) => {
      console.log(error)
    })

  localforage.getItem('deletedSchemes')
    .then((schemes) => {
      if (schemes && schemes.length > 0) {
        const schemesStorage = schemes.filter((scheme) => {
          return Date.now() - scheme.createdAt < cacheLifeTime.value * 1000
        })
        store.commit('schemes/setDeletedSchemes', schemesStorage)
      }
    })
    .catch((error) => {
      console.log(error)
    })
})

watch(activeScheme, (value, oldValue) => {
  if (value && value?.id && value.source !== 'kugi_scheme_data') {
    props.map.setFeatureState(
      {
        source: value.source,
        sourceLayer: value.sourceLayer,
        id: value.id,
      },
      { active: true }
    )
  }

  if (value && value?.id && value.source === 'kugi_scheme_data') {
    setStateSchemeKugi(value.id, { active: true })
  }

  if (oldValue && oldValue?.id && oldValue.source !== 'kugi_scheme_data' && value?.id !== oldValue.id) {
    props.map.setFeatureState(
      {
        source: oldValue.source,
        sourceLayer: oldValue.sourceLayer,
        id: oldValue.id,
      },
      { active: false }
    )
  }

  if (oldValue && oldValue?.id && oldValue.source === 'kugi_scheme_data' && value?.id !== oldValue.id) {
    setStateSchemeKugi(oldValue.id, { active: false })
  }
})


watch(countEditedScheme, () => {
  if (schemes.value.length > 0) {
    repaintSchemesFromStorage({ polygons: schemesStorage.value, map: props.map })
  }
})

watch(schemes, (value) => {
  setFilterScheme()
  if (value?.length === 1 && value[0].geojson) {
    props.map.fitBounds(Turf.bbox(JSON.parse(value[0].geojson)), { maxZoom: 12 })
  }
})

watch(cacheLifeTime, () => {
  const storage = schemesStorage.value.filter((scheme) => {
    return Date.now() - scheme.createdAt < cacheLifeTime.value * 1000
  })
  store.commit('schemes/setSchemesStorage', storage)

  const deletedSchemesStorage = deletedSchemes.value.filter((scheme) => {
    return Date.now() - scheme.createdAt < cacheLifeTime.value * 1000
  })
  store.commit('schemes/setDeletedSchemes', deletedSchemesStorage)
})

watch(deletedSchemes, (value) => {
  const schemes = value.map((item) =>({
    ...toRaw(item),
    geometry: { ...toRaw(item.geometry), coordinates: toRaw(item.geometry.coordinates) },
    properties: toRaw(item.properties)
  }))
  localforage.setItem('deletedSchemes', schemes).catch((error) => console.log(error))
  repaintSchemesFromStorage({ polygons: value, map: props.map })
}, { deep: true })

watch(schemesStorage, (value) => {
  const schemes = value.map((item) =>({
    ...toRaw(item),
    geometry: { ...toRaw(item.geometry), coordinates: toRaw(item.geometry.coordinates) },
    properties: toRaw(item.properties)
  }))
  localforage.setItem('schemes', schemes).catch((error) => console.log(error))
  if (value.length > 0) {
    repaintSchemesFromStorage({ polygons: value, map: props.map })
  }
}, { deep: true })


watch(isVisibleSchemeLayer, (value) => {
  if (props.map.getLayer('kugi_scheme_fill')) {
    if (value) {
      props.map.setLayoutProperty('kugi_scheme_fill', 'visibility', 'visible')
      props.map.setLayoutProperty('kugi_scheme_line', 'visibility', 'visible')
    } else {
      props.map.setLayoutProperty('kugi_scheme_fill', 'visibility', 'none')
      props.map.setLayoutProperty('kugi_scheme_line', 'visibility', 'none')
    }
  }

  if (props.map.getLayer('sh_kugi_fill')) {
    if (value) {
      props.map.setLayoutProperty('sh_kugi_fill', 'visibility', 'visible')
      props.map.setLayoutProperty('sh_kugi_line', 'visibility', 'visible')
    } else {
      props.map.setLayoutProperty('sh_kugi_fill', 'visibility', 'none')
      props.map.setLayoutProperty('sh_kugi_line', 'visibility', 'none')
    }
  }

  if (!value) {
    store.commit('schemes/setCurrentScheme', null)
    store.commit('schemes/setActiveScheme', null)
  }
})

watch(geometryScheme, (value) => {
  store.commit('schemes/setAreaSchemeError', '')

  if (value) {
    uploadSchemePolygon(value)
    ApiService.map.checkArea({ geometry: value, type: 'Feature' })
      .then((response) => {
        store.commit('schemes/setAreaScheme', Number(response.data.area)) // * 10000
      })
      .catch((error) => {
        const errorText = error?.response?.data?.message
        store.commit('schemes/setAreaScheme', null)
        store.commit('schemes/setAreaSchemeError', errorText ? errorText : 'Ошибка')
      })
  } else {
    removeCreatedLayer(props.map)
    store.commit('schemes/setAreaScheme', null)
  }
})
</script>
