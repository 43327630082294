import ApiService from '@/services'
import router from '@/router'

const state = () => ({
  status: '',
  passwordExpired: false,
  token: localStorage.getItem('token') || '',
  user : {
    permissions: []
  },
  // permissions: ['access_cabinet_investor', 'access_cabinet_sections', 'access_cabinet_actions', 'access_cabinet_map', 'section_filter_region', 'access_cabinet_questionnaire']
})

// getters
const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  urlPath: state => state.url,
  userItem: state => (state.user!='') ? state.user : '',
}

// actions
const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      ApiService.auth.login(user)
        .then((response) => {
          const { data } = response
          if (data.errors){
            reject(data.errors)
          } else {
            localStorage.setItem('token', data.token)
            commit('setUser', data.user)
            commit('auth_success', data.token)
            resolve(data)
          }
        })
        .catch((err) => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err.response)
        })
    })
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.auth.logout()
        .then(() => {
          commit('logout')
          localStorage.removeItem('token')
          resolve()
        })
        .catch((err) => {
          localStorage.removeItem('token')
          document.cookie = 'name=laravel_token; expires=Thu, 01 Jan 1970 00:00:00 GMT;'
          reject(err)
        })
    })
  },
  fetchUser({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.auth.getUser()
        .then((response) => {
          commit('setUser', response.data)
          resolve(response)
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            commit('setPasswordExpired', true)
            router.push('/update-password')
            resolve()
          } else {
            commit('logout')
            reject(error)
          }
        })
    })
  },
  updatePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.auth.updatePassword(data)
        .then(() => {
          commit('setPasswordExpired', false)
          router.push('/')
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  auth_request(state){
    state.status = 'loading'
  },
  auth_success(state, token){
    state.status = 'success'
    state.token = token
  },
  auth_error(state){
    state.status = 'error'
  },
  logout(state){
    state.status = ''
    state.token = ''
  },
  setUser(state, data) {
    state.user = data
  },
  setPasswordExpired(state, value) {
    state.passwordExpired = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
