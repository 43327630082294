<template>
  <q-scroll-area
    :style="style"
    :vertical-thumb-style="{ width: '5px', opacity: '0.5' }"
    :horizontal-thumb-style="{ height: '5px', opacity: '0.5' }"
  >
    <table
      v-if="fullProps && fullProps.length"
      class="field__table"
      :class="{active: isActive}"
      ref="tableRef"
      data-testid="table"
    >
      <thead>
        <tr v-if="isActive && fullProps">
          <th class="field__sticky">Сезон посадки</th>
          <th v-for="attribute, index in fullProps" :key="index">{{getValue(attribute.common, "Сезон посадки")}}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="field__sticky field__sticky-column" :class="operationYear ? '' : 'warning'">Год ввода в оборот</td>
          <template v-if="!isActive">
            <td>{{ operationYear }}</td>
          </template>
          <template v-else>
            <td v-for="attribute, index in fullProps" :key="index">{{ operationYear }}</td>
          </template>
        </tr>
        <tr>
          <td class="field__sticky field__sticky-column" :class="efisVegTypeId ? '' : 'warning'">Видовой состав растительного покрова</td>
          <template v-if="!isActive">
            <td>{{ efisVegTypeId }}</td>
          </template>
          <template v-else>
            <td v-for="attribute, index in fullProps" :key="index">{{ efisVegTypeId }}</td>
          </template>
        </tr>
        <template v-for="item in attributes" :key="item">
          <tr v-if="!checkHiddenAttribute(fullProps, item)" :data-testid="item">
            <td
              v-if="isActive || mainAttributes.includes(item)"
              class="field__sticky field__sticky-column"
              :class="highlightAttributes.includes(item) && isActive ? getStyle(fullProps, item) : ''"
            >
              {{ item }}
            </td>
            <template v-if="mainAttributes.includes(item) && !isActive && fullProps && fullProps.length > 0">
              <td>{{ item === "Фактический пользователь" ? getValue(propsOfCurrentPeriod(fullProps).common, item)?.join(", ") : getValue(propsOfCurrentPeriod(fullProps).common, item) }}</td>
            </template>
            <template v-if="isActive">
              <td
                v-for="attribute, index in fullProps"
                :key="index"
                :class="highlightAttributes.includes(item) && activePeriods.includes(attribute?.form?.periodId) ? getStyle([attribute], item) : ''"
              >
                {{ item === "Фактический пользователь" ? getValue(attribute.common, item)?.join(", ") : getValue(attribute.common, item) }}
              </td>
            </template>
          </tr>
        </template>
        <tr v-if="isActive">
          <td
            :colspan="fullProps.length + 1 ?? 1"
            :class="isShowEfis ? 'text-negative' : ''"
            @click="isShowEfis = !isShowEfis"
          >
            <span>Дополнительные атрибуты ЕФИС</span>
            <q-icon :name="isShowEfis ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
          </td>
        </tr>
        <template v-if="isActive && isShowEfis">
          <tr v-for="item in efisAttributes" :key="item">
            <td class="field__sticky field__sticky-column">{{ item }}</td>
            <td v-for="attribute, index in fullProps" :key="index">{{ getValue(attribute.common, item) }}</td>
          </tr>
        </template>

        <template v-for="item in attributes2" :key="item">
          <tr v-if="!checkHiddenAttribute(fullProps, item)" :data-testid="item">
            <td
              v-if="isActive || mainAttributes.includes(item)"
              class="field__sticky field__sticky-column"
              :class="highlightAttributes.includes(item) && isActive ? getStyle(fullProps, item) : ''"
            >
              {{ item }}
            </td>
            <template v-if="mainAttributes.includes(item) && !isActive && fullProps && fullProps.length > 0">
              <td>{{ item === "Фактический пользователь" ? getValue(propsOfCurrentPeriod(fullProps).common, item)?.join(", ") : getValue(propsOfCurrentPeriod(fullProps).common, item) }}</td>
            </template>
            <template v-if="isActive">
              <td
                v-for="attribute, index in fullProps"
                :key="index"
                :class="highlightAttributes.includes(item) && activePeriods.includes(attribute?.form?.periodId) ? getStyle([attribute], item) : ''"
              >
                {{ item === "Фактический пользователь" ? getValue(attribute.common, item)?.join(", ") : getValue(attribute.common, item) }}
              </td>
            </template>
          </tr>
        </template>

        <tr v-if="isActive">
          <td
            :colspan="fullProps.length + 1 ?? 1"
            :class="isShowEnrichment ? 'text-negative' : ''"
            @click="isShowEnrichment = !isShowEnrichment"
          >
            <span>Атрибуты о внесении удобрений</span>
            <q-icon :name="isShowEnrichment ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
          </td>
        </tr>
        <template v-if="isActive && isShowEnrichment">
          <tr v-for="item in enrichmentAttributes" :key="item">
            <td class="field__sticky field__sticky-column">{{ item }}</td>
            <td v-for="attribute, index in fullProps" :key="index">{{ getValue(attribute.mineralFertArea, item) }}</td>
          </tr>
        </template>
        <tr v-if="isActive">
          <td
            :colspan="fullProps.length + 1 ?? 1"
            :class="isShowWinter ? 'text-negative' : ''"
            @click="isShowWinter = !isShowWinter"
          >
            <span>Атрибуты озимых</span>
            <q-icon :name="isShowWinter ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
          </td>
        </tr>
        <template v-if="isActive && isShowWinter">
          <tr v-for="item in winterAttributes" :key="item">
            <td class="field__sticky field__sticky-column">{{ item }}</td>
            <td v-for="attribute, index in fullProps" :key="index">{{ getValue(attribute.ozSeason, item) }}</td>
          </tr>
        </template>
        <tr v-if="isActive" data-testid="Предоставление гос. поддержки">
          <td class="field__sticky field__sticky-column">Предоставление гос. поддержки</td>
          <td v-for="attribute, index in fullProps" :key="index"> {{ getValue(attribute.common, "Предоставление гос. поддержки") }}</td>
        </tr>
        <tr v-if="isActive" data-testid="Размер гос. поддержки">
          <td class="field__sticky field__sticky-column">Размер гос. поддержки</td>
          <td v-for="attribute, index in fullProps" :key="index">{{ getValue(attribute.common, "Размер гос. поддержки") }}</td>
        </tr>
        <tr v-if="isActive" data-testid="Примечание">
          <td class="field__sticky field__sticky-column">Примечание</td>
          <td v-for="attribute, index in fullProps" :key="index">{{ getValue(attribute.common, "Примечание") }}</td>
        </tr>
        <tr data-testid="Статус проверки">
          <td class="field__sticky field__sticky-column" :class="isActive ? getStyle(fullProps, 'Статус проверки') : ''">Статус проверки</td>
          <template v-if="!isActive">
            <td>{{ getValue(propsOfCurrentPeriod(fullProps).common, "Статус проверки") }}</td>
          </template>
          <template v-if="isActive">
            <td
              v-for="attribute, index in fullProps"
              :key="index"
              :class="activePeriods.includes(attribute?.form?.periodId) ? getStyle([attribute], 'Статус проверки') : ''"
            >
              <FieldStatus
                :id="id"
                :full-props="attribute"
                :title="getValue(attribute.common, 'Статус проверки')"
                :disable="visibleEntity === 'sections' || !activePeriods.includes(attribute?.form?.periodId)"
              />
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </q-scroll-area>
</template>
<script setup>
import { onMounted, ref, defineProps, computed, watch } from 'vue'
import { useStore } from 'vuex'
import FieldStatus from '@/modules/map/components/controls/FieldStatus.vue'

const winterAttributes = [
  'Озимые, погибшие до оконч. сева яровых',
  'Озимые на зел. корм, силос/выпас с севом яровых',
  'Сохр-ся озим. к концу сева яровых',
  'Сохр-ся озим. к концу сева яровых на зел. корм, силос, для гранул и брикетов / выпас, без сева яровых'
]

const enrichmentAttributes = [
  'Азотные (N)', 'Фосфорные (Р2О5)', 'Калийные (К2О)',
  'Органические', 'Известкование почв', 'Гипсование почв',
  'Фосфоритование', 'Внесено извест. материалов', 'Внесено гипсосодержащих пород',
  'Внесено фосфоритной муки'
]

const efisAttributes = [
  'Биологическая активность',
  'Год пользования (для многолетних трав)',
  'Рекомендации по использованию земельного участка',
  'Плотность растительного покрова',
  'Информация о гос. программе в рамках которой проведено обследование',
  'Сидерат',
  'Подпокровные травы',
  'Многолетние травы'
]

const attributes = ['Площадь', 'Фактическая эксплуатация', 'Сложность ввода в эксплуатацию', 'Сведения о состоянии неиспользуемых земель', 'Фактический пользователь', 'Вид с/х угодий', 'Вид культур', 'ОКПД 2']

const attributes2 = ['Тип севооборота', 'Сорт', 'Валовый сбор урожая', 'Урожайность', 'Вид итоговой продукции']

const mainAttributes = ['Площадь', 'Фактическая эксплуатация', 'Фактический пользователь', 'Вид с/х угодий', 'Вид культур']

const highlightAttributes = ['Площадь', 'Фактическая эксплуатация', 'Год ввода в оборот', 'Сложность ввода в эксплуатацию', 'Сведения о состоянии неиспользуемых земель', 'Фактический пользователь', 'Вид с/х угодий', 'Вид культур', 'Тип севооборота']

const style = ref('')
const isShowEnrichment = ref(false)
const isShowWinter = ref(false)
const isShowEfis = ref(false)
const tableRef = ref()

const props = defineProps(['props', 'isActive', 'id', 'operationYear', 'efisVegTypeId'])
const store = useStore()

const fullProps = computed(() => props.props.filter((prop) => prop.common.length > 0))
const currentPeriod = computed(() => store.state.dictionaries.periods.find((period) => period.isNow))
const activePeriods = computed(() => store.state.dictionaries.periods.filter((period) => period.active).map((period) => period.id))
const isCountChangeWidthDetails = computed(() => store.state.details.isCountChangeWidthDetails)
const visibleEntity = computed(() => store.state.map.visibleEntity)

const propsOfActivePeriods = (props) => props.filter((prop) => activePeriods.value.includes(prop.form.periodId))

const getValue = (values, name) => values.find((item) => item.name === name)?.value ? values.find((item) => item.name === name).value : null

const checkHiddenAttribute = (props, attribute) => props.every((prop) => getValue(prop.common, attribute) === 'Не указывается')

const getStyle = (props, attribute) => {
  const activePeriodsProps = propsOfActivePeriods(props)
  const activePeriodsAttributes = activePeriodsProps.map((item) => getValue(item.common, attribute))

  if (activePeriodsAttributes?.some((item) => !item)) {
    return 'warning'
  }

  if (activePeriodsAttributes?.some((item) =>
    item === 'Нет данных'
    || item === 'Организация не указана'
    || item === 'Данные не верны'
    || item === 'Требуется проверка'
    || item === 'Требует внимания'
  )) {
    return 'negative'
  }

  return ''
}

const propsOfCurrentPeriod = (props) => {
  const currentProps = props.find((prop) => prop.form.periodId === currentPeriod.value?.id)
  return currentProps ? currentProps : props[0]
}

watch([isCountChangeWidthDetails, isShowEnrichment, isShowWinter, isShowEfis], () => {
  setTimeout(() => {
    style.value = `height: ${tableRef.value?.clientHeight}px; max-width: 100%;`
  }, 0)
})

onMounted(() => {
  style.value = `height: ${tableRef.value?.clientHeight}px; max-width: 100%;`
})
</script>
<style lang="scss" scoped>
.field {
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid $color-gray3;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    border: 1px solid $color-main;
  }

  &--opened,
  &--opened:hover {
    border: none;
  }

  &__delete-date {
    padding-top: 10px;
  }

  &__top {
    display: flex;
    margin-bottom: 10px;
  }

  &__column {
    width: 70%;
  }

  &__title {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 5px;
      width: 20px;
      height: 20px;
    }
  }


  &__show {
    padding: 3px 0;
    color: $color-active;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__table {
    border-collapse: collapse;

    td, th {
      max-width: 50%;
    }
  }

  &__table.active {
    margin: 0 auto;

    td {
      max-width: 50%;
      box-shadow: inset 0 0 0 0.5px $color-gray3;
    }

    th {
      max-width: 50%;
      box-shadow: inset 0 0 0 0.5px $color-gray3;
    }

  }

  td {
    text-align: left;
    padding: 5px;
  }

  th {
    text-align: center;
    padding: 5px;
  }


  &__table.active &__sticky {
    position: sticky;
    z-index: 1;
    left: 0;
    background-color: #f4f7fa;

    &.warning {
      background-color: #f3ecd3;
    }

    &.negative {
      background-color: #eed2d2;
    }
  }

  &__table.active &__sticky-column {
    padding: 5px;
    border: none;
    box-shadow: inset 0 0 0 0.5px $color-gray3;
  }

  .bold {
    font-weight: 700;
  }

  &__buttons {
    padding: 20px;
  }

  .button {
    width: 100%;
    margin-bottom: 7px;
  }

  .warning {
    background-color: #f3ecd3;
  }

  .negative {
    background-color: #eed2d2;
  }
}
</style>
