const satellitesLayers = [
  {
    type: 'farm',
    group: 'satellites',
    name: 'sentinel_0621_data',
    label: 'Спутник',
    value: 'sentinel_0621_fill',
    options: {
      type: 'raster',
      tiles: [(process.env.VUE_APP_GEOSERVER_JAVA_URL || 'https://geo.sovhoz.tech/geoserver') + '/gwc/service/wmts?layer=sovhoz:sentinel_0621&tilematrixset=EPSG:900913&bbox=84.44775%2C52.1625%2C89.40275%2C56.8345&Service=WMTS&Request=GetTile&Version=1.0.0&format=image/png&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}']
    },
    layers: [{
      id: 'sentinel_0621_fill',
      type: 'raster',
      source: 'sentinel_0621_data',
      'source-layer': 'sentinel_0621',
      layout: {
        'visibility': 'none'
      },
      paint: {
        'raster-opacity': 1
      }
    },]
  },
  {
    type: 'farm',
    group: 'satellites',
    name: 'wms_data',
    label: 'Sentinel-2',
    value: 'wms_2020',
    options: {
      type: 'raster',
      tiles: [
        // 'https://tiles.maps.eox.at/?service=wms&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&format=image/jpeg&layers=s2cloudless-2020_3857&styles&tiled=true&DPI=96&MAP_RESOLUTION=96&FORMAT_OPTIONS=dpi:96&&bbox={bbox-epsg-3857}',
        'https://s2maps-tiles.eu/wmts?layer=s2cloudless-2021_3857&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}'
      ]
    },
    layers: [
      {
        id: 'wms_2020',
        type: 'raster',
        source: 'wms_data',
        'source-layer': 'wms-data',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'raster-opacity': 1
        }
      },
    ]
  },
  {
    type: 'farm',
    group: 'satellites',
    name: 'maxar_data',
    label: 'Maxar (2016-2020)',
    value: 'maxar_2020',
    options: {
      type: 'raster',
      tiles: [
        'https://wayback.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/WMTS/1.0.0/default028mm/MapServer/tile/48232/{z}/{y}/{x}',
      ]
    },
    layers: [
      {
        id: 'maxar_2020',
        type: 'raster',
        source: 'maxar_data',
        'source-layer': 'maxar_data',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'raster-opacity': 1
        }
      },
    ]
  },
  {
    type: 'farm',
    group: 'satellites',
    name: 'sentinel_data',
    label: 'Sentinel',
    value: 'sentinel',
    options: {
      type: 'raster',
      tiles: [
        'https://services.sentinel-hub.com/ogc/wms/ed64bf38-72da-4723-9c06-568b76b8add0?REQUEST=GetMap&SERVICE=WMS&LAYERS=2_TONEMAPPED_NATURAL_COLOR&WIDTH=512&HEIGHT=512&SHOWLOGO=false&TIME=2022-05/2022-08&PRIORITY=leastCC&bbox={bbox-epsg-3857}'
        // 2_TONEMAPPED_NATURAL_COLOR
        // 3_NDVI
      ]
    },
    layers: [
      {
        id: 'sentinel',
        type: 'raster',
        source: 'sentinel_data',
        'source-layer': 'sentinel_data',
        layout: {
          'visibility': 'none'
        },
        paint: {
          'raster-opacity': 1
        }
      },
    ]
  },
]

export { satellitesLayers }
